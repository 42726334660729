export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72')
];

export const server_loads = [];

export const dictionary = {
		"/": [22],
		"/activation": [23,[2]],
		"/activation/confirmation": [24,[2]],
		"/admin": [25,[3]],
		"/admin/announcement": [26,[3]],
		"/admin/announcement/new": [27,[3]],
		"/admin/announcement/preview": [28,[4]],
		"/admin/announcement/update/[announcementId]": [29,[3]],
		"/admin/users": [30,[3]],
		"/ai": [31,[5]],
		"/ai/competitive-analysis": [32,[5,6]],
		"/ai/document-chat": [33,[5]],
		"/ai/home": [34,[5,7]],
		"/ai/open-chat": [35,[5]],
		"/ai/organization": [36,[5]],
		"/ai/purchase": [37,[5]],
		"/ai/register": [38,[8]],
		"/ai/reports": [39,[5,9]],
		"/ai/resources": [40,[5,10]],
		"/ai/sign-in": [41,[11]],
		"/ai/sign-in/sso/confirmation": [42,[11]],
		"/ai/sustainability-analysis": [43,[5,12]],
		"/ai/welcome": [44,[5]],
		"/email-verify/[...activationdata]": [45],
		"/platform/i18n": [47],
		"/platform/view-org": [48,[13]],
		"/platform/[slug]/users/onboarding": [46],
		"/purpose": [49],
		"/purpose/announcement/[announcementId]/[announcementTitleSlug]": [50,[14]],
		"/purpose/campaigns": [55,[14]],
		"/purpose/campaign": [51],
		"/purpose/campaign/[campaignHandle]": [52,[14]],
		"/purpose/campaign/[campaignHandle]/action/[actionType]/[actionHandle]": [53,[14]],
		"/purpose/campaign/[campaignHandle]/learn/[learnHandle]": [54,[14,15]],
		"/purpose/discuss": [56,[14,16]],
		"/purpose/discuss/[handle]": [57,[14,16]],
		"/purpose/impact": [58,[14]],
		"/purpose/learn": [59,[14,17]],
		"/purpose/profile": [60,[14]],
		"/purpose/profile/edit": [61,[14]],
		"/purpose/team": [62,[14]],
		"/purpose/training": [63,[14]],
		"/purpose/training/[handle]": [64,[14]],
		"/reset-password": [65,[18]],
		"/sign-in": [66,[19]],
		"/sign-in/password": [67,[19]],
		"/sign-in/sso": [68,[19]],
		"/sign-in/sso/confirmation": [69],
		"/unauthorized": [70],
		"/unsubscribe": [71,[20]],
		"/wellness/[...subroute]": [72,[21]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';